import { Box, Button, TextField, useTheme, Checkbox, FormControlLabel, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { tokens } from "../../theme";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    
    // Initialize username from localStorage if it exists
    const [username, setUsername] = useState(() => localStorage.getItem("rememberedUsername") || "");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    
    // Initialize rememberMe based on whether username exists in storage
    const [rememberMe, setRememberMe] = useState(() => !!localStorage.getItem("rememberedUsername"));

    const handleLogin = () => {
    if (username === "cdadmin" && password === "cdjordan_1234") {
        localStorage.setItem("isAuthenticated", "true");
        localStorage.setItem("userRole", "admin");
        if (rememberMe) {
            localStorage.setItem("rememberedUsername", username);
        } else {
            localStorage.removeItem("rememberedUsername");
        }
        navigate("/dashboard");
    } 
    else if (username === "cdguest" && password === "jordanguest_4321") {
        localStorage.setItem("isAuthenticated", "true");
        localStorage.setItem("userRole", "viewer");
        if (rememberMe) {
            localStorage.setItem("rememberedUsername", username);
        } else {
            localStorage.removeItem("rememberedUsername");
        }
        navigate("/entries");
    }
    else {
        alert("Invalid credentials");
    }
};

    const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    };

    return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor={colors.primary[400]}
    >
      <Box
        p={3}
        bgcolor={colors.primary[400]}
        borderRadius="4px"
        boxShadow={3}
        width="300px"
      >
        <TextField
          fullWidth
          label="Username"
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Password"
          type={showPassword ? "text" : "password"}
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ mb: 2 }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              sx={{
                color: colors.greenAccent[500],
                '&.Mui-checked': {
                  color: colors.greenAccent[500],
                },
              }}
            />
          }
          label="Remember Me"
          sx={{ mb: 2, color: colors.grey[100] }}
        />
        <Button
          fullWidth
          variant="contained"
          onClick={handleLogin}
          sx={{
            bgcolor: colors.greenAccent[600],
            "&:hover": { bgcolor: colors.greenAccent[700] },
          }}
        >
          Login
        </Button>
      </Box>
    </Box>
  );
};

export default Login;