import { Box, Typography, useTheme, MenuItem, FormControl, Select, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
//import { mockDataTeam } from "../../data/mockData";
import { useEffect, useState } from "react";
import Link from "@mui/material/Link";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { createClient } from "@supabase/supabase-js";
import emailjs from '@emailjs/browser';
// Add these imports at the top
import { Modal } from "@mui/material";
import { Button } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import DownloadIcon from '@mui/icons-material/Download';
import { TextField } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import * as XLSX from 'xlsx';

emailjs.init("htlhXEhJZcUdUYUmm");

//Initialize Supabase Client
const supabase = createClient('https://jjnrtzeudsxoxbvjxpff.supabase.co', 
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpqbnJ0emV1ZHN4b3hidmp4cGZmIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjkzMDE0MDMsImV4cCI6MjA0NDg3NzQwM30.KXCkWzisWyVAScCHyuJ2dt29uig3i51PQcv9bsyJwDw')

const ContestEntries = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [guestData, setGuessData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null); // Move this line here
  const [selectedImageData, setSelectedImageData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editingRemarks, setEditingRemarks] = useState(null);
  const [remarksText, setRemarksText] = useState("");
  const userRole = localStorage.getItem("userRole");

  const updateRemarks = async (id, remarks) => {
    try {
      const { error } = await supabase
        .from('cust_dt')
        .update({ remarks: remarks })
        .eq('id', id);
      
      if (error) throw error;

      // Update local state
      setGuessData(prevData => 
        prevData.map(row => 
          row.id === id ? { ...row, remarks: remarks } : row
        )
      );
      setEditingRemarks(null);
      setRemarksText("");
    } catch (error) {
      console.error('Error updating remarks:', error.message);
    }
  };

  
  const handleExportData = () => {
    const worksheet = XLSX.utils.json_to_sheet(guestData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Contest Entries");
  
    // Generate and download the Excel file
    XLSX.writeFile(workbook, "contest_entries.xlsx");
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxWidth: '90%',
    maxHeight: '90%',
  };

  const handleExportSelected = () => {
    const selectedData = guestData.filter(row => selectedRows.includes(row.id));
    const worksheet = XLSX.utils.json_to_sheet(selectedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Selected Entries");
    XLSX.writeFile(workbook, "selected_entries.xlsx");
  };

  const updateBulkStatus = async (newStatus) => {
    for (const id of selectedRows) {
      await updateEntryStatus(id, newStatus);
    }
  };

  useEffect(() => {
    fetchGuestData();
  }, []);

  const fetchGuestData = async () => {
    try {
        const { data, error } = await supabase
            .from('cust_dt')
            .select('*');
        if (error) throw error;
        setGuessData(data);
    }
        catch(error) {
            console.log('Error fetching guest data:', error.message);
        }
  };

  const updateEntryStatus = async (id, newStatus) => {
    const statusValue = newStatus === "Approve" ? true : false;
    try {
      const { error } = await supabase
        .from('cust_dt')
        .update({ entries_status: statusValue })
        .eq('id', id);
      
      if (error) throw error;

      // Update local state with the updated status
      setGuessData(prevData => 
        prevData.map(row => 
          row.id === id ? { ...row, entries_status: statusValue } : row
        )
      );
   // Find the row data to get the email and name
//    const rowData = guestData.find(row => row.id === id);
//    const templateParams = {
//      name: rowData.name,
//      email: rowData.email,
//    };

    // Send email based on status
//    if (newStatus === "Approve") {
//      try {
//        const result = await emailjs.send(
//          'service_7rlt4si',
//          'template_milwssy', // Approval template ID
//          templateParams
//        );
//        console.log('Approval email sent successfully', result);
//      } catch (emailError) {
//        console.error('Error sending approval email:', emailError);
//      }
//    } else if (newStatus === "Reject") {
//      try {
//        const result = await emailjs.send(
//          'service_7rlt4si',
//          'template_rgxj6yy', // Replace with your rejection template ID
//          templateParams
//        );
//        console.log('Rejection email sent successfully', result);
//      } catch (emailError) {
//        console.error('Error sending rejection email:', emailError);
//      }
//    }
  } catch (error) {
    console.error('Error updating status:', error.message);
  }
};
  
  const columns = [
    { field: "id", 
      headerName: "ID",
      flex: 0.3 },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "contact",
      headerName: "Mobile Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.5,
    },
    {
      field: "ageGroup",
      headerName: "Age Group",
      flex: 0.5,
    },
    {
      field: "ethnicity",
      headerName: "Ethnicity",
      flex: 1,
    },
    {
      field: "q1",
      headerName: "Question 1",
      flex: 1,
    },
    {
      field: "purchase_outlet",
      headerName: "Purchase Outlet",
      flex: 1,
    },
    {
      field: "receipt_no",
      headerName: "Receipt Number",
      flex: 1,
    },
    {
      field: "receipt_date",
      headerName: "Receipt Date",
      flex: 1,
    },
    {
      field: "slogan",
      headerName: "Slogan",
      flex: 2,
      renderCell: (params) => (
        <Box sx={{ whiteSpace: 'normal', padding: '10px 0' }}>
          <Typography variant="body2">
            {params.value}
          </Typography>
        </Box>
      )
    },
    {
      field: "receipt_url",
      headerName: "Receipt Image",
      flex: 1,
      renderCell: (params) => {
        if (!params.row.receipt_url) {
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
             <Typography 
                variant="body2" 
                color={colors.grey[100]}
                sx={{ 
                  textAlign: 'center',
                  width: '100%'
                }}
              >
            No Image Uploaded
          </Typography>
        </Box>
          );
        }

        return (
          <>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img
                src={params.row.receipt_url}
                alt="Receipt"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setSelectedImageData({
                    url: params.row.receipt_url,
                    receiptNo: params.row.receipt_no,
                    receiptDate: params.row.receipt_date
                  });
                  setSelectedRow(params.row.id);
                }}

              />
            </Box>
            <Modal
              open={Boolean(selectedImageData)}
              onClose={() => setSelectedImageData(null)}
            >
              <Box sx={modalStyle}>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center',
                  mb: 2 
                }}>
                  <Typography variant="h6">
                    Receipt Number: {selectedImageData?.receiptNo}
                  </Typography>
                  <Typography variant="h6">
                    Receipt Date: {selectedImageData?.receiptDate}
                  </Typography>
                </Box>
                <img
                  src={selectedImageData?.url}
                  alt="Receipt"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '80vh',
                    objectFit: 'contain'
                  }}
                />
              </Box>
            </Modal>
          </>
        );
      }
    },
    {
  field: "entries_status",
  headerName: "Entries Status",
  flex: 1.2,
  renderCell: (params) => {
    const currentStatus = params.row.entries_status === true 
      ? "Approved" 
      : params.row.entries_status === false 
      ? "Rejected" 
      : "Pending";

    const userRole = localStorage.getItem("userRole");
    
    if (userRole === "viewer") {
      return (
        <Typography
          variant="body2"
          sx={{
            color: currentStatus === "Approved" 
              ? colors.greenAccent[500]
              : currentStatus === "Rejected" 
              ? colors.redAccent[500]
              : colors.grey[100]
          }}
        >
          {currentStatus}
        </Typography>
      );
    }

    // Keep original "Approve/Reject" for admin dropdown
    const dropdownStatus = params.row.entries_status === true 
      ? "Approve" 
      : params.row.entries_status === false 
      ? "Reject" 
      : "Pending";

    return (
      <FormControl fullWidth>
        <Select
          value={dropdownStatus}
          onChange={(e) => updateEntryStatus(params.row.id, e.target.value)}
          sx={{
            color: colors.grey[100],
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grey[100],
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.greenAccent[500],
            },
            '.MuiSvgIcon-root': {
              color: colors.grey[100],
            },
          }}
        >
          <MenuItem value="Pending" disabled>Pending</MenuItem>
          <MenuItem value="Approve">Approve</MenuItem>
          <MenuItem value="Reject">Reject</MenuItem>
        </Select>
      </FormControl>
    );
  }
},
{
      field: "remarks",
      headerName: "Remarks",
      flex: 1.5,
      renderCell: (params) => {
        const isEditing = editingRemarks === params.row.id;
        
        if (isEditing) {
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                width: '100%'
              }}
              onClick={(e) => e.stopPropagation()} // Prevent DataGrid cell selection
            >
              <TextField
                fullWidth
                multiline
                variant="outlined"
                value={remarksText}
                onChange={(e) => setRemarksText(e.target.value)}
                onKeyDown={(e) => {
                  e.stopPropagation(); // Prevent DataGrid from capturing keyboard events
                }}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent cell selection when clicking the TextField
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: colors.grey[100],
                    '& fieldset': {
                      borderColor: colors.grey[100],
                    },
                    '&:hover fieldset': {
                      borderColor: colors.greenAccent[500],
                    },
                  }
                }}
              />
              <IconButton 
                onClick={(e) => {
                  e.stopPropagation();
                  updateRemarks(params.row.id, remarksText);
                }}
                sx={{ color: colors.greenAccent[500] }}
              >
                <CheckIcon />
              </IconButton>
            </Box>
          );
        }

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              width: '100%'
            }}
          >
            <Typography
              variant="body2"
              sx={{
                whiteSpace: 'normal',
                overflow: 'hidden',
                flex: 1
              }}
            >
              {params.value || "No remarks"}
            </Typography>
            <IconButton 
              onClick={(e) => {
                e.stopPropagation();
                setEditingRemarks(params.row.id);
                setRemarksText(params.value || "");
              }}
              sx={{ color: colors.blueAccent[400] }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        );
      }
    }

//    {
//      field: "accessLevel",
//      headerName: "Access Level",
//      flex: 1,
//      renderCell: ({ row: { access } }) => {
//        return (
//          <Box
//            width="60%"
//            m="0 auto"
//            p="5px"
//            display="flex"
//            justifyContent="center"
//            backgroundColor={
//              access === "admin"
//                ? colors.greenAccent[600]
//                : access === "manager"
//                ? colors.greenAccent[700]
//                : colors.greenAccent[700]
//            }
//            borderRadius="4px"
//          >
//            {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
//            {access === "manager" && <SecurityOutlinedIcon />}
//            {access === "user" && <LockOpenOutlinedIcon />}
//            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
//              {access}
//            </Typography>
//          </Box>
//        );
//      },
//    },
  ].filter(column => {
        if (userRole === "viewer") {
            // Remove edit/update capabilities for viewers
            return ![ "remarks"].includes(column.field);
        }
        return true;
    });

  

  return (
    <Box m="20px">
      <Header title="JORDAN CONTEST" subtitle="Participants' Entries" />
      <Box
  display="flex"
  justifyContent="flex-end"
  gap={2}
  mb={2}
>
  <Button
    onClick={handleExportData}
    variant="contained"
    sx={{
      backgroundColor: colors.blueAccent[700],
      '&:hover': {
        backgroundColor: colors.blueAccent[800],
      }
    }}
    startIcon={<DownloadIcon />}
  >
    Download All Data
  </Button>
  <Button
    onClick={handleExportSelected}
    variant="contained"
    disabled={selectedRows.length === 0}
    sx={{
      backgroundColor: colors.blueAccent[700],
      '&:hover': {
        backgroundColor: colors.blueAccent[800],
      }
    }}
    startIcon={<DownloadIcon />}
  >
    Download Selected ({selectedRows.length})
  </Button>
  <Button
    onClick={() => updateBulkStatus("Approve")}
    variant="contained"
    disabled={selectedRows.length === 0}
    sx={{
      backgroundColor: colors.greenAccent[600],
      '&:hover': {
        backgroundColor: colors.greenAccent[700],
      }
    }}
  >
    Approve Selected
  </Button>
  <Button
    onClick={() => updateBulkStatus("Reject")}
    variant="contained"
    disabled={selectedRows.length === 0}
    sx={{
      backgroundColor: colors.redAccent[600],
      '&:hover': {
        backgroundColor: colors.redAccent[700],
      }
    }}
  >
    Reject Selected
  </Button>
</Box>
      
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
       <DataGrid
          checkboxSelection
          rows={guestData}
          columns={columns}
          getRowHeight={() => 120}
          rowSelectionModel={selectedRows}
          onRowSelectionModelChange={(newSelection) => {
            setSelectedRows(newSelection);
          }}
          initialState={{
            sorting: {
            sortModel: [{ field: 'id', sort: 'asc' }],
            },
          }}  
          components={{
            Toolbar: GridToolbar
          }}
          sx={{
            '& .MuiDataGrid-cell': {
              borderBottom: "none",
              whiteSpace: 'normal !important',
              lineHeight: '20px !important',
              maxHeight: '120px',
              overflow: 'auto',
            },
            '& .Mui-selected': {
              backgroundColor: `${colors.greenAccent[700]} !important`,
            }
          }}
          showCellRightBorder={false}
          showColumnRightBorder={false}
          columnOrder={[
            ...columns.map(col => col.field),
            '__check__'  // This moves checkbox column to the end
          ]}
        />
      </Box>
    </Box>
  );
};

export default ContestEntries;
